angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.templates', {
            url: '/templates',
            views: {
                "main": {
                    controller: 'TemplatesCtrl',
                    templateUrl: 'web/main/templates/templates.tpl.html',
                    controllerAs: 'controller'
                }
            },
            resolve: {
                templates : (templateService)=>  templateService.getPlantillas()
            }, 
            data: {
                pageTitle: 'Templates'
            }
        });
    })



    .controller('TemplatesCtrl', function ($scope, templateService, session, templates) {
        var that = this;
        that.session = session.get();
        that.plan = that.session.plan;
        that.credits = session.getRemainingCredits();
        $scope.$on("sessionChanged", () => that.plan = session.get().plan);

        that.loading = true;
        that.update = ()=> templateService.getPlantillas().then(r=>{
            that.plantillas = r.data;
            that.loading = false;
        });
        that.update();
    });
